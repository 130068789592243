@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* 



██████  ██    ██ ███    ██ ███    ██ ██ ███    ██  ██████      
██   ██ ██    ██ ████   ██ ████   ██ ██ ████   ██ ██           
██████  ██    ██ ██ ██  ██ ██ ██  ██ ██ ██ ██  ██ ██   ███     
██   ██ ██    ██ ██  ██ ██ ██  ██ ██ ██ ██  ██ ██ ██    ██     
██   ██  ██████  ██   ████ ██   ████ ██ ██   ████  ██████      
                                                               
                                                               
██████   ██████   ██████ ██   ██ ███████                       
██   ██ ██    ██ ██      ██  ██  ██                            
██████  ██    ██ ██      █████   ███████                       
██   ██ ██    ██ ██      ██  ██       ██                       
██   ██  ██████   ██████ ██   ██ ███████                       
                                                               
                                                               
November 17. 2023
*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html { background: #282c34;}
body {
  font-family: 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 1.2rem;
}

h1 {
  font-size: 6rem;
}
.App {
  text-align: center;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}
.video {
  width:100%;
  clear: both;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    xanimation: App-logo-spin infinite 20s linear;
  }
}
a, a:link, a:visited {
  color:rgb(176, 195, 201);
}
h5 { font-size: 1.75rem;}
.App-header {
  background-color: #282c34;
  background-size: cover;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.btn-darkprimary {
  color: #ffffff !important;
  background-color: #0e1f39 !important;

}
.App-link {
  color: #61dafb;
}
/* temperature */
.bottom {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgba(11, 11, 11, 0.2);
}

.bold {
  font-weight: 700;
}
.masthead {
  font-family: 'Outfit';
  font-weight: 800;
  font-style:italic;
  font-size: 2em;
}
/* cards */
.fa-2x {
  font-size: 2em;
}
.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}
.card .card-header {
  font-weight: 500;
}
.card-header:first-child {
  border-radius: 0.35rem 0.35rem 0 0;
}
.card-header {
  padding: 1rem 1.35rem;
  margin-bottom: 0;
  background-color: rgba(33, 40, 50, 0.03);
  border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}
.border-start-primary {
  border-left-color: #0061f2 !important;
}
.border-start-secondary {
  border-left-color: #6900c7 !important;
}
.border-start-success {
  border-left-color: #00ac69 !important;
}
.border-start-lg {
  border-left-width: 0.25rem !important;
}
.h-100 {
  height: 100% !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
